// Generated by Framer (f7d95e4)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const cycleOrder = ["AS4UXjytN", "T_jTnWUgi"];

const serializationHash = "framer-0Q8kH"

const variantClassNames = {AS4UXjytN: "framer-v-a2fh7y", T_jTnWUgi: "framer-v-1jwz8ie"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transition2 = {bounce: 0, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "AS4UXjytN", "Variant 2": "T_jTnWUgi"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "AS4UXjytN"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "AS4UXjytN", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1mhds2m = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("T_jTnWUgi")
})

const onTaplocw7y = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("AS4UXjytN")
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({T_jTnWUgi: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-a2fh7y", className, classNames)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"AS4UXjytN"} onTap={onTap1mhds2m} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "rgb(207, 207, 207)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} {...addPropertyOverrides({T_jTnWUgi: {"data-framer-name": "Variant 2", onTap: onTaplocw7y}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-160x58i-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"d_PvdRmpS-container"} nodeId={"d_PvdRmpS"} rendersWithMotion scopeId={"W73ZCfdHJ"} style={{opacity: 0}} variants={{T_jTnWUgi: {opacity: 1}}}><Feather color={"rgb(4, 114, 199)"} height={"100%"} iconSearch={"Home"} iconSelection={"check"} id={"d_PvdRmpS"} layoutId={"d_PvdRmpS"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0Q8kH.framer-yvjao, .framer-0Q8kH .framer-yvjao { display: block; }", ".framer-0Q8kH.framer-a2fh7y { cursor: pointer; height: 26px; position: relative; width: 26px; }", ".framer-0Q8kH .framer-160x58i-container { flex: none; height: 24px; left: calc(50.00000000000002% - 24px / 2); position: absolute; top: calc(50.00000000000002% - 24px / 2); width: 24px; }", ".framer-0Q8kH[data-border=\"true\"]::after, .framer-0Q8kH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 26
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"T_jTnWUgi":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerW73ZCfdHJ: React.ComponentType<Props> = withCSS(Component, css, "framer-0Q8kH") as typeof Component;
export default FramerW73ZCfdHJ;

FramerW73ZCfdHJ.displayName = "check-box";

FramerW73ZCfdHJ.defaultProps = {height: 26, width: 26};

addPropertyControls(FramerW73ZCfdHJ, {variant: {options: ["AS4UXjytN", "T_jTnWUgi"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerW73ZCfdHJ, [{explicitInter: true, fonts: []}, ...FeatherFonts], {supportsExplicitInterCodegen: true})